import { createHttpEndpoint } from '../../utils'

export interface SwapListingOffer {
  listing_id: number
  swap_discount: number
  swap_discount_with_currency: string
  price_with_swap_discount: number
  price_with_swap_discount_with_currency: string
  cart_has_swap_offer: boolean
  model: string | null
}

/**
 * Swap Initial Discount
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/SwapInitialDiscount}
 */
export const getEstimationSwapPrice = createHttpEndpoint<
  Array<SwapListingOffer>
>({
  method: 'GET',
  operationId: 'getEstimationSwapPrice',
  path: '/bm/sourcing/swap/initial_discount',
})
