<template>
  <section class="pb-32">
    <h3 class="heading-3 mb-16">
      {{ props.categories?.title }}
    </h3>

    <RevButtonBase
      v-for="option in props.categories?.options"
      :key="option?.value"
      class="bg-action-default-low shadow-short rounded-sm mt-8 flex w-full cursor-pointer items-center px-16 py-12 hover:bg-action-default-low"
      :data-qa="`category-${option.value}`"
      @click="$emit('next-step', { category: option.value })"
    >
      <div class="grow text-left">
        <p class="body-1">
          {{ option.label }}
        </p>
      </div>

      <RevIllustration
        v-if="option.icon?.light"
        alt=""
        :height="48"
        :src="option.icon.light"
        :width="48"
      />
    </RevButtonBase>
  </section>
</template>

<script lang="ts" setup>
import { buybackCustomerAPI } from '@backmarket/http-api'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

interface CategoriesProps {
  categories: buybackCustomerAPI.Question | Record<string, never>
}

export interface CategoryPayload {
  category: string
}

const props = withDefaults(defineProps<CategoriesProps>(), {
  categories: () => ({}),
})

defineEmits<{
  (e: 'next-step', category: CategoryPayload): void
}>()
</script>
