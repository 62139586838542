<template>
  <section class="flex h-full flex-col">
    <RevIllustration
      alt=""
      class="mt-4"
      data-test="intro-illustration"
      :height="244"
      src="/img/buyback/swapIntroView.svg"
      :width="387"
    />
    <h2 class="heading-3 mt-4 text-center">
      {{ i18n(translations.ad, { price: props.price }) }}
    </h2>
    <h3 class="body-2-bold mt-20 text-center">
      {{ i18n(translations.purposal) }}
    </h3>
    <div class="mt-auto">
      <RevButton
        class="mt-16"
        data-qa="user-yes"
        data-test="user-yes"
        full-width="always"
        variant="secondary"
        @click="startSwap"
      >
        <span class="heading-3 block">
          {{ i18n(translations.yes) }}
        </span>
        <span class="body-2 block">
          {{ i18n(translations.yesSubtitle) }}
        </span>
      </RevButton>
      <RevButton
        class="mt-16"
        data-qa="user-no"
        data-test="user-no"
        full-width="always"
        variant="secondary"
        @click="discardSwap"
      >
        <span class="heading-3 block">
          {{ i18n(translations.no) }}
        </span>
        <span class="body-2 block">
          {{ props.closeWording }}
        </span>
      </RevButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Intro.translations'

interface IntroProps {
  title: string
  price: string
  closeWording: string
}

const i18n = useI18n()

const props = defineProps<IntroProps>()

const emit = defineEmits(['close', 'next-step'])

function discardSwap() {
  emit('close')
}

function startSwap() {
  emit('next-step')
}
</script>
