export default {
  ad: {
    id: 'swap_ad_v2',
    defaultMessage:
      'Want to get this for even less? Like {price}*? Trade in your old tech for cash.',
  },
  purposal: {
    id: 'swap_buyback_intro_journey_purposal_v2',
    defaultMessage:
      '*Price shown is calculated based on the average order values and may vary',
  },
  yes: {
    id: 'swap_buyback_purposal_yes',
    defaultMessage: 'Yes',
  },
  yesSubtitle: {
    id: 'swap_buyback_purposal_yes_subtitle',
    defaultMessage: 'Estimate the buyback offer >',
  },
  no: {
    id: 'swap_buyback_purposal_no',
    defaultMessage: 'No',
  },
}
