import { useRoute, useRouteParams } from '#imports'
import { computed } from 'vue'

import { type Grade } from '@backmarket/http-api'

export type UrlParams = Readonly<{
  productId: string
  grade?: Grade['value']
  offerType?: 7
  mobilePlan?: string
}>

export const useUrlParams = () => {
  const { uuid: productId } = useRouteParams()
  const route = useRoute()

  const grade = computed(() => {
    const l = route.query?.l || null

    const v = Array.isArray(l) ? l[0] : l

    return v ? parseInt(v, 10) : null
  })

  const offer = computed(() => {
    const offerType = route.query?.offerType || null
    const v = Array.isArray(offerType) ? offerType[0] : offerType

    return v ? parseInt(v, 10) : null
  })

  const mobilePlan = computed(() => {
    const mobilePlanOfferId = route.query?.mobilePlanOfferId || null
    const v = Array.isArray(mobilePlanOfferId)
      ? mobilePlanOfferId[0]
      : mobilePlanOfferId

    return v ?? null
  })

  return {
    productId,
    grade,
    offerType: offer,
    mobilePlan,
  }
}
