<template>
  <div>
    <SwapModal
      :datalayer-category="props.productCategory"
      :modal-name="props.modalName"
      :price="swapOfferPrice"
      :product-title="props.model"
      :skip-intro-step="!props.shouldDisplaySwapBlock"
      :swap-listings="props.swapListings"
      @confirmation="$emit('confirmation')"
    />
    <div
      v-if="shouldDisplaySwapBlock"
      class="border-static-default-low rounded-md box-border flex flex-col items-stretch justify-between border p-24"
      :class="{ 'sm:border-0 md:border-0': compact }"
    >
      <div class="mb-12 flex items-center justify-between">
        <RevSkeleton
          v-if="isLoading"
          :aria-label="i18n(translations.loading)"
          class="shrink-0"
          height="3.6rem"
          shape="circle"
          width="3.6rem"
        />

        <div
          v-if="!isLoading"
          class="bg-action-default-hi text-onaction-default-hi rounded-full m-4 flex items-center p-8"
        >
          <IconSwap class="m-auto" />
        </div>

        <span class="body-1-bold ml-16 flex-1">
          <RevSkeleton
            v-if="isLoading"
            :aria-label="i18n(translations.loading)"
            shape="line"
          />

          <span v-if="!isLoading">
            {{ i18n(translations.swapTitle) }}
          </span>
        </span>
        <RevTooltip
          content-class-names="m-24 max-w-[22.5rem] md:m-0"
          :modifiers="popoverModifiers"
          position="top-end"
        >
          <template #trigger="{ show }">
            <RevSkeleton
              v-if="isLoading"
              :aria-label="i18n(translations.loading)"
              class="ml-8"
              height="1.6rem"
              shape="circle"
              width="1.6rem"
            />

            <RevButtonRounded
              v-if="!isLoading"
              class="-mr-12 cursor-pointer"
              :disabled="false"
              :icon="IconInfo"
              variant="secondary"
              @click="show"
            />
          </template>
          {{ i18n(translations.swapTooltip) }}
        </RevTooltip>
      </div>
      <div
        class="flex flex-col md:flex-row md:items-start"
        :class="{
          'flex-col md:flex-col': compact || swapLayout === 'vertical',
        }"
      >
        <RevSkeleton
          v-if="isLoading"
          :aria-label="i18n(translations.loading)"
          :class="{
            'mb-7 md:mb-0': !compact,
            'mb-6 md:mb-6': compact,
          }"
          height="3.2rem"
          shape="rectangle"
          width="100%"
        />

        <span
          v-if="!isLoading && !hasFailed"
          class="body-2"
          :class="{
            'mb-7 md:mb-0': !compact,
            'mb-6 md:mb-6': compact || swapLayout === 'vertical',
          }"
        >
          <FormattedMessage
            data-test="swap-message"
            :definition="translations.swapSubtitle"
            :values="{ hasSwapOffer }"
          >
            <template #model>
              {{ model }}
            </template>
            <template #price>
              <strong>
                {{
                  i18n(translations.swapPrice, {
                    price: swapOfferPrice,
                    isPriceForFree: isPriceForFree,
                  })
                }}
              </strong>
            </template>
            <template #itemToSwap>
              <strong>
                {{ swapOfferTitle }}
              </strong>
            </template>
          </FormattedMessage>
        </span>

        <RevButton
          class="ml-0 md:flex-1"
          :class="{ 'md:ml-7': hasButtonMargins }"
          data-qa="swap-cta"
          data-test="swap-cta"
          full-width="always"
          variant="secondary"
          @click.stop="handleSwap"
        >
          <RevSkeleton
            v-if="isLoading"
            :aria-label="i18n(translations.loading)"
            shape="line"
          >
            {{
              i18n(translations.getSwapEstimation, { hasOffer: hasSwapOffer })
            }}
          </RevSkeleton>

          <span v-if="!isLoading">
            {{
              i18n(translations.getSwapEstimation, { hasOffer: hasSwapOffer })
            }}
          </span>
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { openModal } from '@ds/components/ModalBase'
import { RevSkeleton } from '@ds/components/Skeleton'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconSwap } from '@ds/icons/IconSwap'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import SwapModal from '../SwapModal/SwapModal.vue'

import { SWAP_ERROR, SWAP_LOADING } from './SwapBlock.constants'
import translations from './SwapBlock.translations'

const { trackSwap } = useTracking()

defineEmits(['refresh-estimations', 'confirmation', 'continue'])

const i18n = useI18n()

// swapOffer refers to an API model in browsing scope (get estimations)
// not yet migrated
const props = withDefaults(
  defineProps<{
    model: string
    swapOffer: Estimation
    swapListings?: Array<number>
    swapRedirection: string
    swapLayout?: string
    gradeId: number
    productCategory: string
    compact?: boolean
    trackingLabel: string
    modalName: string
    swapStatus: string
    shouldDisplaySwapBlock?: boolean
  }>(),
  {
    model: undefined,
    swapOffer: undefined,
    swapListings: () => [],
    swapRedirection: 'none',
    swapLayout: 'default',
    gradeId: undefined,
    productCategory: '',
    compact: false,
    trackingLabel: undefined,
    modalName: undefined,
    swapStatus: undefined,
    shouldDisplaySwapBlock: true,
  },
)

const isLoading = computed(() => {
  return props.swapStatus === SWAP_LOADING
})

const hasFailed = computed(() => {
  return props.swapStatus === SWAP_ERROR
})

const hasSwapOffer = computed(() => {
  return props.swapOffer && props.swapOffer.hasOffer
})

const swapOfferPrice = computed(() => {
  return props.swapOffer?.price ?? ''
})

const isPriceForFree = computed(() => {
  const priceAmount = parseInt(swapOfferPrice.value.split(' ')[0], 10)

  return priceAmount === 0
})

const swapOfferTitle = computed(() => {
  return !hasSwapOffer.value ? '' : props.swapOffer.model
})

const hasButtonMargins = computed(() => {
  return !props.compact && !hasFailed.value && props.swapLayout !== 'vertical'
})

const popoverModifiers = computed(() => {
  return [
    {
      name: 'offset',
      options: {
        offset: [0, 25],
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top'],
      },
    },
  ]
})

function handleSwap() {
  trackSwap({
    label: props.trackingLabel,
    category: props.productCategory,
  })

  openModal(props.modalName)
}
</script>
